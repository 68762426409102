import React from "react";

function Card(props) {
    const tags = props.tags.map((tag, i) => (
        <li key={i} className="btn btn--outline btn--secondary btn--small">
            <p>{tag}</p>
        </li>
    ));
    return (
        <a className="card" href={`${props.link}`}>
            <div className="content-container">
                {props.hot && <div className="btn btn--filled btn--small btn--red btn--hot btn--no-click">hot!</div>}
                <div className="image" style={{ backgroundImage: "url(" + props.img + ")" }}>
                    {/* <img src={`${props.img}`} alt="" /> */}
                </div>
                <div className="content">
                    <div className="information">
                        <h4>{props.title}</h4>
                        <p>{props.body}</p>
                        <div className="btn btn--filled">Ty it</div>
                    </div>
                    <div className="tags">
                        <ul className="flex">{tags}</ul>
                    </div>
                </div>
            </div>
        </a>
    );
}

export default Card;
