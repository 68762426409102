import React from "react";
import SubmitIdea from "./submitIdea";

function Header(props) {
    return (
        <header>
            <h2>{props.title}</h2>
            <p className="excerpt" dangerouslySetInnerHTML={{ __html: props.body }} />
            <SubmitIdea />
        </header>
    );
}

export default Header;
