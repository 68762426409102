import React from "react";
import firebase, { db } from "./firebase";

let count = 0;
let firebaseConn = false;
let thumbsUpCollection = null;
let updateDatabase = null;
function thumbsup(e) {
    e.preventDefault();
    clearTimeout(updateDatabase);

    const target = e.currentTarget;
    const element = e.currentTarget.previousSibling;

    target.classList.remove("click");
    target.classList.add("click");

    if (firebaseConn === false) {
        thumbsUpCollection = db.collection("thumbsUp").doc("count");
        thumbsUpCollection
            .get()
            .then((doc) => {
                if (doc.exists) count = doc.data().value;
            })
            .then(() => {
                target.classList.remove("click");
                count++;
                element.innerHTML = `Hey! thanks there are already: <strong>${count} hearts in the air!</strong>`;
                firebaseConn = true;
            })
            .catch((err) => {
                firebaseConn = false;
                target.classList.remove("click");
                return Promise.reject(err);
            });
    }

    if (firebaseConn === true) {
        window.setTimeout((el) => {
            target.classList.remove("click");
            count++;
            element.innerHTML = `Hey! thanks there are already: <strong>${count} hearts in the air!</strong>`;
        }, 300);
    }

    updateDatabase = window.setTimeout(() => thumbsUpCollection.update({ value: count }), 5000);
}

function ThumbsUp(props) {
    return (
        <section className="give-feedback">
            <p>{props.text}</p>
            <i onClick={thumbsup}>{props.icon}</i>
        </section>
    );
}

export default ThumbsUp;
