//https://softauthor.com/firestore-security-rules
import firebase from "firebase/app";
import firestore from "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDDXanzT594fL8XBlGru6-DcULwud3cpqI",
    authDomain: "tools-sek-fi.firebaseapp.com",
    databaseURL: "https://tools-sek-fi.firebaseio.com",
    projectId: "tools-sek-fi",
    storageBucket: "tools-sek-fi.appspot.com",
    messagingSenderId: "787949868483",
    appId: "1:787949868483:web:c206404f23d085f1e81976",
};
// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
