export class Notifications {
    constructor() {
        this.container = document.querySelector("#app--notifications");
        this.notification = "";
        this.autoRemoveTimeout = null;
    }

    createHTML(obj) {
        let notification = document.createElement("div");
        obj.type = obj.type === undefined || obj.type === null ? "general" : obj.type;
        notification.classList.add("notification", `type-${obj.type}`);

        let content = document.createElement("div");
        content.classList.add("content");
        notification.append(content);

        let img = document.createElement("div");
        img.classList.add("img");

        img.innerHTML = `${obj.img}`;
        content.append(img);

        let p = document.createElement("p");
        p.classList.add("p");
        p.innerHTML = obj.text;
        content.append(p);

        let close = document.createElement("div");
        close.classList.add("close");
        notification.append(close);

        this.notification = notification;

        this.show(obj.time);
    }
    show(time) {
        this.container.append(this.notification);
        setTimeout(() => this.notification.classList.add("show"), 100);
        this.remove();
        this.autoRemove(time);
    }
    remove() {
        let closeBtn = this.notification.querySelector(".close");
        closeBtn.addEventListener("click", function (e) {
            e.currentTarget.parentElement.classList.add("remove");
            setTimeout(() => {
                this.parentElement.remove();
            }, 700);
        });
    }
    autoRemove(time) {
        this.autoRemoveTimeout = setTimeout(() => {
            this.notification.querySelector(".close").click();
        }, time);
    }
}
