import React from "react";
import { modal } from "../App";
import firebase, { db } from "./firebase";

//GET DATA
function getSuggestions() {
    db.collection("ideaSuggestions")
        .get()
        .then((doc) => {
            if (doc.exists) {
                doc.forEach((element) => {
                    console.log(element.data());
                });
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

//SAVE DATA
function saveSuggestion(suggestion) {
    db.collection("ideaSuggestions")
        .add({
            idea: suggestion,
        })
        .then(() => {
            modal("Ooh yeah! Your idea seems great and we will take a closer look at it 🤩");
        });
}

function SubmitIdea(props) {
    return (
        <section className="submit-idea">
            <p>
                Got a cool <strong>project</strong> idea?
            </p>
            <form name="suggest-your-idea" id="yourIdeaForm" method="POST" data-netlify="true">
                <input id="yourIdea" type="text" name="idea-suggestion" placeholder="Your idea 😎" />
                <p className="input--error">Your idea seems quite short 🤨</p>

                <button
                    id="submitIdea"
                    onClick={(e) => {
                        e.preventDefault();
                        let yourIdea = document.getElementById("yourIdea");
                        let inputError = document.querySelector(".input--error");
                        if (yourIdea.value.length >= 10) {
                            inputError.classList.remove("show");
                            yourIdea.classList.remove("error");
                            saveSuggestion(yourIdea.value);
                            yourIdea.value = "";
                        } else {
                            inputError.classList.add("show");
                            yourIdea.classList.add("error");
                        }
                    }}
                    className="btn btn--outline btn--custom"
                    href="_self"
                    type="submit"
                >
                    Send
                </button>
            </form>
        </section>
    );
}

export default SubmitIdea;
