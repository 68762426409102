import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import { Notifications as Notification } from "./components/notifications";
import firebase, { db } from "./components/firebase";
import Header from "./components/header";
import ThumbsUp from "./components/spreadSomeLove";
import Card from "./components/card";

import { ReactComponent as HeartEmoji } from "./assets/images/heartEmoji.svg";
import { ReactComponent as Plusicon } from "./assets/images/plus.svg";

import logoCustomizerImg from "./assets/images/sek-logo-customiser.jpg";
import colorHueCustomizerImg from "./assets/images/color-hue-generator.png";
import quickToDoImg from "./assets/images/quick-todo.jpg";

export function modal(text) {
    const modal = document.querySelector(".modal-container");
    modal.childNodes[0].innerHTML = text;
    modal.classList.remove("hidden");

    modal.addEventListener("click", (e) => {
        if (e.target.classList.contains("modal-container")) {
            e.target.classList.add("removing");
            setTimeout(() => {
                e.target.classList.add("hidden");
                e.target.classList.remove("removing");
            }, 500);
        }
    });
}

function App() {
    useEffect(() => {
        let cardCount = document.querySelectorAll(".card").length;

        if (cardCount <= 2) {
            let cardContainer = document.querySelector(".card-container");
            var comingMoreCard = document.createElement("div");
            comingMoreCard.classList.add("card", "card--comingMore", "no--hover");
            comingMoreCard.innerHTML = `<div class="content-container"> <i></i> <p> More tools coming soon! </p> </div>`;

            cardContainer.append(comingMoreCard);
            ReactDOM.render(<Plusicon />, document.querySelector(".content-container i"));
        }


        //ADD REALTIME SUGGESTION LISTENER
        db.collection("ideaSuggestions").onSnapshot((doc) => {
            let changes = doc.docChanges();
            changes.forEach((change) => {
                if (doc.metadata.hasPendingWrites === false && changes.length === 1 && doc.size > 1 && change.type === "added") {
                    new Notification().createHTML({ text: "<span>Nice: </span> Someone just submitted a new idea!", img: "🥳", time: 6000 });
                }
            });
        });
    });

    return (
        <Fragment>
            <div id="app--notifications"> </div>
            <div className="modal-container hidden">
                <div className="content"></div>
            </div>
            <div className="app">
                <ThumbsUp text="Spread some love" icon={<HeartEmoji />} />
                <div className="app-container">
                    <Header title="Tools & fun projects" body="A collection of apps/tools made at SEK creative agency. The concept is to solve problems and create new interesting design tools with code." />

                    <div className="card-container">
                        <Card
                            img={logoCustomizerImg}
                            title="Logo customizer"
                            body="This app allows you to create personalized SEK letter graphic for client or other presentations."
                            link="../tools/sek-logo-customiser"
                            tags={["tool", "app"]}
                            hot={false}
                        />
                        <Card
                            img={colorHueCustomizerImg}
                            title="Color hue generator"
                            body="Quickly generate new hues of a specific color. The new hues try to keep their color value that was originally given. The tool also suggests text contrast."
                            link="../tools/color-hue-generator"
                            tags={["design", "tool"]}
                            hot={false}
                        />
                        <Card
                            img={quickToDoImg}
                            title="Quick To-do"
                            body="Quickly create and share to-do lists with your friends and colleagues."
                            link="../tools/quick-todo"
                            tags={["app", "tool", "management"]}
                            hot={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default App;
